import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import styled from '@emotion/styled';

import PageLayout from '../components/PageLayout';
import Sections from '../components/Sections';
import { useSuccessStories } from '../queries/useSuccessStories';
import { ContentMain, RichTextView } from '../components/commonStyled';
import { useMenu } from '../queries/useMenu';
import { getUrl } from '../utils';
import { useAllPosts } from '../queries/useAllPosts';
import { ROUTES } from '../constants';
import { MenuItemModel } from '../models';

export default ({ data, location }) => {
    const {
        title,
        seo,
        common_acf: { blocks },
    } = data?.wpPage;

    const menus = useMenu();
    const successStories = useSuccessStories();
    const articles = useAllPosts();

    const newMenus: MenuItemModel[] = [
        { title: 'Главная', url: '/' },
        ...menus.map((x) => {
            if (x.url.includes(ROUTES.istoriiUspeha)) {
                return {
                    ...x,
                    child_items: successStories.map((s) => ({
                        url: `/${ROUTES.istoriiUspeha}/${s.slug}`,
                        title: s.name,
                    })),
                };
            }

            if (x.url.includes(ROUTES.articles)) {
                return {
                    ...x,
                    child_items: articles.map((a) => ({
                        url: `/${ROUTES.articles}/${a.slug}`,
                        title: a.title,
                    })),
                };
            }

            return x;
        }),
    ];

    return (
        <PageLayout
            breadcrumbs={[
                {
                    url: location?.pathname,
                    title,
                },
            ]}
            title={title}
            seoMeta={seo}
        >
            <ContentMain>
                <RichTextView>
                    <ul>
                        {newMenus.map((item, i) => (
                            <li key={i}>
                                <Link
                                    to={getUrl(item).url}
                                    dangerouslySetInnerHTML={{
                                        __html: item.title,
                                    }}
                                />
                                {item.child_items?.length && (
                                    <ul>
                                        {item.child_items
                                            ?.filter(
                                                (x) => !getUrl(x).isExternal
                                            )
                                            .map((subItem, index) => (
                                                <li key={index}>
                                                    <Link
                                                        to={getUrl(subItem).url}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                subItem.title,
                                                        }}
                                                    />
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </RichTextView>
            </ContentMain>
            <Sections
                enabledSections={blocks}
                options={{
                    actionBlockMiddle: {
                        whiteStyle: true,
                        noButton: true,
                        order: 3,
                    },
                }}
            />
        </PageLayout>
    );
};

export const pageQuery = graphql`
    query KartaSaitaPage {
        wpPage(common_acf: { id: { eq: "karta-saita" } }) {
            title
            seo {
                ...Seo
            }
            common_acf {
                blocks
            }
        }
    }
`;
