import { useStaticQuery, graphql } from 'gatsby';

import { PostStickyModel } from '../models';

export const useAllPosts = (): PostStickyModel[] => {
    const { allWpPost } = useStaticQuery(
        graphql`
            {
                allWpPost(filter: { status: { eq: "publish" } }) {
                    nodes {
                        slug
                        title
                    }
                }
            }
        `
    );

    return allWpPost?.nodes || [];
};
